<template>
    <div class="maincontent">
        <div class="container">
            <div class="row mt-4 d-flex justify-content-center">
                <div class="col-12 col-md-8 text-center description">
                    <h3> Grundrisse für die einzelnen Wohnung</h3>
                    <span class="text-muted">Interessieren Sie sich für eine Wohnung in unserem Komplex?<br>
                    Hier können Sie sich die einzelnen Grundrisse der Wohnungen in Ruhe ansehen, sollten Sie eine gefunden haben die Ihnen zusagt, nehmen Sie
                    bitte gerne Kontakt zu uns auf über das Kontaktformular, das Sie über die Navigationsbar unter dem Reiter Kontakt finden können. <br>
                    Alle Angaben zu den Wohnungen ohne Gewähr.
                    </span>
                </div>
            </div>
            <div class="row mt-4 d-flex justify-content-center">
                <div class="col-12 col-md-8 description">
                    <div class="row">
                        <div class="col-12 col-md-3">
                            <span class="text-muted">Wohnungen filtern nach:</span>
                        </div>
                        <div class="col-12 col-md-3 mt-2 mt-md-0">
                            <b-form-select v-model="rooms" :options="roomOptions">
                                <template #first>
                                    <b-form-select-option :value="null" disabled>-- Zimmeranzahl --</b-form-select-option>
                                </template>
                            </b-form-select>
                        </div>
                        <div class="col-12 col-md-3 mt-2 mt-md-0">
                           <b-form-select v-model="rentCold" :options="rentOptions">
                               <template #first>
                                    <b-form-select-option :value="null" disabled>-- Kaltmiete --</b-form-select-option>
                                </template>
                           </b-form-select>
                        </div>
                        <div class="col-12 col-md-3 mt-2 mt-md-0">
                            <b-form-select v-model="livingSpace" :options="spaceOptions">
                                <template #first>
                                    <b-form-select-option :value="null" disabled>-- Wohnfläche --</b-form-select-option>
                                </template>
                            </b-form-select>
                        </div>
                    </div>
                </div>
            </div>  
            <div class="row mt-4 d-flex justify-content-center">
                <div class="col-12 col-md-5 text-center fixedHeight" v-if="flatsLocal.length<1"> 
                    <vueper-slides
                        ref="vueperslides1"
                        @slide="$refs.vueperslides2 && $refs.vueperslides2.goToSlide($event.currentSlide.index, { emit: false })"
                        @before-slide="saveSlide($event)"
                        :slide-ratio="1 / 4"
                        :bullets="false"
                        arrows-outside
                        fixed-height=600px>
                        <vueper-slide
                            v-for="(flat, i) in flats" 
                            :key="i"
                            :image="require(`@/assets/Bauplaene/SingleFlats/${flat.value}.png`)"
                            :title="flat.value"
                            :style="'min-width: 100%; background-repeat: no-repeat; background-size: contain;'"
                         />
                    </vueper-slides>
                    <vueper-slides
                        class="mt-4"
                        ref="vueperslides2"
                        :slide-ratio="1 / 8"
                        :dragging-distance="50"
                        @slide="$refs.vueperslides1 && $refs.vueperslides1.goToSlide($event.currentSlide.index, { emit: false })"
                        @before-slide="saveSlide($event)"
                        :visible-slides="3"
                        fixed-height="150px">
                        <vueper-slide
                            v-for="(flat, i) in flats"
                            :key="i"
                            :title="flat.value"
                            @click.native="$refs.vueperslides2 && $refs.vueperslides2.goToSlide(i - 1)">
                            <template v-slot:content>
                                <div class="vueperslide__content-wrapper">
                                    <img style="max-width:100%" :src="require(`@/assets/Bauplaene/SingleFlats/${flat.value}.png`)" alt="">
                                </div>
                            </template>
                        </vueper-slide>
                    </vueper-slides>
                </div>
                <div class="col-12 col-md-5 text-center fixedHeight" v-else> 
                    <vueper-slides
                        ref="vueperslides1"
                        @slide="$refs.vueperslides2 && $refs.vueperslides2.goToSlide($event.currentSlide.index, { emit: false })"
                        @before-slide="saveSlide($event)"
                        :slide-ratio="1 / 4"
                        :bullets="false"
                        arrows-outside
                        fixed-height=600px>
                        <vueper-slide
                            v-for="(flat, i) in flatsLocal" 
                            :key="i"
                            :image="require(`@/assets/Bauplaene/SingleFlats/${flat.value}.png`)"
                            :title="flat.value"
                            :style="'min-width: 100%; background-repeat: no-repeat; background-size: contain;'"
                         />
                    </vueper-slides>
                    <vueper-slides
                        class="mt-4"
                        ref="vueperslides2"
                        :slide-ratio="1 / 8"
                        :dragging-distance="50"
                        @slide="$refs.vueperslides1 && $refs.vueperslides1.goToSlide($event.currentSlide.index, { emit: false })"
                        @before-slide="saveSlide($event)"
                        :visible-slides="3"
                        fixed-height="150px">
                        <vueper-slide
                            v-for="(flat, i) in flatsLocal"
                            :key="i"
                            :title="flat.value"
                            @click.native="$refs.vueperslides2 && $refs.vueperslides2.goToSlide(i - 1)">
                            <template v-slot:content>
                                <div class="vueperslide__content-wrapper">
                                    <img style="max-width:100%" :src="require(`@/assets/Bauplaene/SingleFlats/${flat.value}.png`)" alt="">
                                </div>
                            </template>
                        </vueper-slide>
                    </vueper-slides>
                </div>
                <div class="col-12 col-md-3 descriptionInfo">
                    <h5 class="mb-4">{{activeFlat.id}}</h5>
                    <span class="mr-4">Anzahl Zimmer: {{activeFlat.Zimmer}}</span> 
                    <!-- <span>Quadratmeter Total: {{activeFlat.Wohnfläche[activeFlat.Wohnfläche.length-1].qm}} qm</span>  -->
                    <h5 class="mt-4 mb-2">Zimmeraufteilung</h5>
                    <table style="min-width:100%">
                        <thead>
                            <tr>
                                <th>Zimmerart</th>
                                <th>Quadratmeter</th>
                                <!-- <th>Faktor</th> -->
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(room,index) in activeFlat.Wohnfläche" :key="index">
                                <td> {{room.typ}} </td>
                                <td v-if="room.typ == 'Gesamt'" class="font-weight-bold"> {{room.qm}} m²</td>
                                <td v-else> {{room.qm}} m²</td>
                                <!-- <td> {{room.faktor}} </td> -->
                            </tr>
                        </tbody>
                    </table>
                    <!-- <h5 class="mt-4 mb-2">Vorraussichtliche Miete in €</h5>
                    <table style="min-width:100%">
                        <thead>
                            <tr>
                                <th>Kaltmiete</th>
                                <th>Nebenkosten*</th>
                                <th>Warm-Miete</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td> {{getInRightForm(activeFlat.miete.kalt)}} </td>
                                <td> {{getInRightForm(activeFlat.miete.nk)}} </td>
                                <td> {{getInRightForm(activeFlat.miete.warm)}} </td>
                            </tr>
                        </tbody>
                    </table>
                    <p class="mt-2">PKW-Stellplatz mtl. 95,00 <br> mit E-Ladeplatz mtl. 135,00</p>
                    <small class="text-muted mb-2">*Nebenkosten beinhalten Betriebs- und Heizkosten</small> -->
                </div>
            </div>
        </div>
    </div>
</template>

<script>
// import Multiselect from 'vue-multiselect'

import { VueperSlides, VueperSlide } from 'vueperslides'
import 'vueperslides/dist/vueperslides.css'

export default {
  name: 'BlueprintFlat',
  components: {
    VueperSlides, VueperSlide
  },
    data (){
        return {
            roomOptions:[
                {value:1, text:'1 Zimmer'},
                {value:2, text:'2 Zimmer'},
                {value:3, text:'3 Zimmer'},
                {value:4, text:'4 Zimmer'},
            ],
            rentOptions:[
                {value:700, text:'bis 700€'},
                {value:900, text:'bis 900€'},
                {value:1200, text:'bis 1200€'},
                {value:1500, text:'bis 1500€'},
                {value:1800, text:'bis 1800€'},
            ],
            spaceOptions:[
                {value:40, text:'ab 40m²'},
                {value:50, text:'ab 50m²'},
                {value:60, text:'ab 60m²'},
                {value:70, text:'ab 70m²'},
                {value:80, text:'ab 80m²'},
                {value:90, text:'ab 90m²'},
                {value:100, text:'ab 100m²'},
            ],
            rooms: null,
            rentCold: null,
            livingSpace: null,
            slide: 0,
            sliding: null,
            flatsLocal: [],
            activeFlat:{id:'WE_01',
                Zimmer:2,
                Wohnfläche:[
                    { typ:'Flur',qm:4.56,faktor:1},
                    { typ:'Bad',qm:5.99,faktor:1},
                    { typ:'Schlafen',qm:13.58,faktor:1},
                    { typ:'Wohnen/Kochen',qm:21.31,faktor:1},
                    { typ:'Terrasse',qm:4.57,faktor:0.5},
                    { typ:'Gesamt',qm:47.73},
                ],
                miete:{
                    kalt:828.60,
                    nk:138.00,
                    warm:966.60
                }},
        }
    },
    methods:{
        getInRightForm(value){
            return parseFloat(value).toFixed(2)
        },
        onSlideStart(slide) {
            this.sliding = true
        },
        onSlideEnd(slide) {
            this.sliding = false
        },
        saveSlide(evt){
            this.activeFlat = evt.nextSlide.title
            var options = this.flatInfos
            for (let i = 0; i < options.length; i++) {
                const element = options[i];
                if(element.id == evt.nextSlide.title) this.activeFlat = element
            }
        },
        filterFlats(value, type){
            var matchingInfos = []
            var matchingFlats = []
            if (type == 'rooms') {
                matchingInfos = this.flatInfos.filter(e => { return e.Zimmer == value})
                this.flats.forEach(flat => {
                    matchingInfos.forEach(info =>{
                        if(flat.value == info.id) matchingFlats.push(flat)
                    })
                });
                this.flats = matchingFlats
            }else if(type == 'rent'){
                matchingInfos = this.flatInfos.filter(e => { return e.miete.kalt <= value})
                this.flats.forEach(flat => {
                    matchingInfos.forEach(info =>{
                        if(flat.value == info.id) matchingFlats.push(flat)
                    })
                });
                this.flats = matchingFlats
            }else {
                this.flatInfos.forEach(info =>{
                    info.Wohnfläche.forEach(room => {
                        if (room.typ == 'Gesamt' && room.qm >= value) {
                            matchingInfos.push(info)
                        }
                    })
                })
                this.flats.forEach(flat => {
                    matchingInfos.forEach(info =>{
                        if(flat.value == info.id) matchingFlats.push(flat)
                    })
                });
                this.flats = matchingFlats
            }
            this.activeFlat = matchingInfos[0]
        }
    },
    computed:{
        flats:{
            get(){
                var options = [
                    {id: 1, value:'WE_01'},
                    {id: 2, value:'WE_02'},
                    {id: 3, value:'WE_03'},
                    {id: 4, value:'WE_04'},
                    {id: 5, value:'WE_05'},
                    {id: 6, value:'WE_06'},
                    {id: 7, value:'WE_07'},
                    {id: 8, value:'WE_08'},
                    {id: 9, value:'WE_09B'},
                    {id: 10, value:'WE_10'},
                    {id: 11, value:'WE_11B'},
                    {id: 12, value:'WE_12'},
                    {id: 13, value:'WE_13'},
                    {id: 14, value:'WE_14B'},
                    {id: 15, value:'WE_15'},
                    {id: 16, value:'WE_16'},
                    {id: 17, value:'WE_17B'},
                    {id: 18, value:'WE_18'},
                    {id: 19, value:'WE_19'},
                    {id: 20, value:'WE_20'},
                    {id: 21, value:'WE_21'},
                    {id: 22, value:'WE_22'},
                    {id: 23, value:'WE_23'},
                    {id: 24, value:'WE_24B'},
                    {id: 25, value:'WE_25'},
                    {id: 26, value:'WE_26'},
                    {id: 27, value:'WE_27B'},
                    {id: 28, value:'WE_28'},
                    {id: 29, value:'WE_29'},
                    {id: 30, value:'WE_30B'},
                    {id: 31, value:'WE_31'},
                    {id: 32, value:'WE_32B'}
                ]
                return options
            },
            set(value){
                this.flatsLocal = value
            }
        },
        flatInfos(){
            var options = [
                {id:'WE_01',
                Zimmer:2,
                Wohnfläche:[
                    { typ:'Flur',qm:4.56,faktor:1},
                    { typ:'Bad',qm:5.99,faktor:1},
                    { typ:'Schlafen',qm:13.58,faktor:1},
                    { typ:'Wohnen/Kochen',qm:21.31,faktor:1},
                    { typ:'Terrasse',qm:4.57,faktor:0.5},
                    { typ:'Gesamt',qm:47.73},
                ],
                miete:{
                    kalt:828.60,
                    nk:138.00,
                    warm:966.60
                }},
                {id:'WE_02',
                Zimmer:3,
                Wohnfläche:[
                    { typ:'Flur',qm:8.67,faktor:1},
                    { typ:'Bad',qm:7.23,faktor:1},
                    { typ:'Schlafen',qm:14.32,faktor:1},
                    { typ:'Zimmer 1',qm:12.57,faktor:1},
                    { typ:'Wohnen/Kochen',qm:33.97,faktor:1},
                    { typ:'Abstell',qm:1.77,faktor:1},
                    { typ:'Terrasse',qm:21.05,faktor:0.5},
                    { typ:'Gesamt',qm:89.06},
                ],
                miete:{
                    kalt:1416.00,
                    nk:258.00,
                    warm:1674.00
                }},
                {id:'WE_03',
                Zimmer:2,
                Wohnfläche:[
                    { typ: 'Flur', qm:4.22,faktor:1},
                    { typ: 'Bad',qm:6.91,faktor:1},
                    { typ: 'Schlafen',qm:13.57,faktor:1},
                    { typ: 'Wohnen/Kochen',qm:22.63,faktor:1},
                    { typ: 'Loggia',qm:4.30,faktor:0.5},
                    { typ: 'Gesamt',qm:49.48},
                ],
                miete:{
                    kalt:864.50,
                    nk:143.00,
                    warm:1007.50
                }},
                {id:'WE_04',
                Zimmer:3,
                Wohnfläche:[
                    { typ: 'Flur',qm:8.15,faktor:1},
                    { typ: 'Bad',qm:7.23,faktor:1},
                    { typ: 'Schlafen',qm:14.32,faktor:1},
                    { typ: 'Zimmer 1',qm:12.57,faktor:1},
                    { typ: 'Wohnen/Kochen',qm:29.42,faktor:1},
                    { typ: 'Abstell',qm:1.77,faktor:1},
                    { typ: 'Loggia',qm:9.48,faktor:0.5},
                    { typ: 'Gesamt',qm:78.20},
                ],
                miete:{
                    kalt:1271.00,
                    nk:227.00,
                    warm:1498.00
                }},
                {id:'WE_05',
                Zimmer:3,
                Wohnfläche:[
                    { typ: 'Flur',qm:7.83,faktor:1},
                    { typ: 'Bad',qm:8.46,faktor:1},
                    { typ: 'Schlafen',qm:15.07,faktor:1},
                    { typ: 'Zimmer 1',qm:11.76,faktor:1},
                    { typ: 'Wohnen/Kochen',qm:35.89,faktor:1},
                    { typ: 'Abstell',qm:3.82,faktor:1},
                    { typ: 'Loggia',qm:4.42,faktor:0.5},
                    { typ: 'Gesamt',qm:85.04},
                ],
                miete:{
                    kalt:1347.90,
                    nk:247.00,
                    warm:1594.90
                }},
                {id:'WE_06',
                Zimmer:2,
                Wohnfläche:[
                    { typ: 'Flur',qm:4.22,faktor:1},
                    { typ: 'Bad',qm:6.91,faktor:1},
                    { typ: 'Schlafen',qm:13.57,faktor:1},
                    { typ: 'Wohnen/Kochen',qm:22.63,faktor:1},
                    { typ: 'Loggia',qm:4.30,faktor:0.5},
                    { typ: 'Gesamt',qm:47.73},
                ],
                miete:{
                    kalt:880.80,
                    nk:143.00,
                    warm:1023.80
                }},
                {id:'WE_07',
                Zimmer:3,
                Wohnfläche:[
                    { typ: 'Flur',qm:8.15,faktor:1},
                    { typ: 'Bad',qm:7.23,faktor:1},
                    { typ: 'Schlafen',qm:14.32,faktor:1},
                    { typ: 'Zimmer 1',qm:12.57,faktor:1},
                    { typ: 'Wohnen/Kochen',qm:29.42,faktor:1},
                    { typ: 'Abstell',qm:1.77,faktor:1},
                    { typ: 'Loggia',qm:9.48,faktor:0.5},
                    { typ: 'Gesamt',qm:78.20},
                ],
                miete:{
                    kalt:1302.00,
                    nk:230.00,
                    warm:1532.00
                }},
                {id:'WE_08',
                Zimmer:3,
                Wohnfläche:[
                    { typ: 'Flur',qm:7.83,faktor:1},
                    { typ: 'Bad',qm:8.46,faktor:1},
                    { typ: 'Schlafen',qm:15.07,faktor:1},
                    { typ: 'Zimmer 1',qm:11.76,faktor:1},
                    { typ: 'Wohnen/Kochen',qm:35.89,faktor:1},
                    { typ: 'Abstell',qm:3.82,faktor:1},
                    { typ: 'Loggia',qm:4.42,faktor:0.5},
                    { typ: 'Gesamt',qm:85.04},
                ],
                miete:{
                    kalt:1392.00,
                    nk:256.00,
                    warm:1648.00
                }},
                {id:'WE_09B',
                Zimmer:3,
                Wohnfläche:[
                    { typ: 'Flur',qm:11.62,faktor:1},
                    { typ: 'Bad',qm:6.70,faktor:1},
                    { typ: 'Schlafen',qm:18.05,faktor:1},
                    { typ: 'Zimmer 1',qm:10.46,faktor:1},
                    { typ: 'Wohnen/Kochen',qm:29.69,faktor:1},
                    { typ: 'Loggia',qm:9.48,faktor:0.5},
                    { typ: 'Gesamt',qm:79.26},
                ],
                miete:{
                    kalt:1387.00,
                    nk:230.00,
                    warm:1617.00
                }},
                {id:'WE_10',
                Zimmer:3,
                Wohnfläche:[
                    { typ: 'Flur',qm:7.47,faktor:1},
                    { typ: 'Bad',qm:8.42,faktor:1},
                    { typ: 'Schlafen',qm:11.55,faktor:1},
                    { typ: 'Zimmer 1',qm:9.33,faktor:1},
                    { typ: 'Wohnen/Kochen',qm:28.16,faktor:1},
                    { typ: 'Abstell',qm:3.73,faktor:1},
                    { typ: 'Loggia',qm:8.55,faktor:0.5},
                    { typ: 'Gesamt',qm:72.94},
                ],
                miete:{
                    kalt:1283.90,
                    nk:211.00,
                    warm:1494.90
                }},
                {id:'WE_11B',
                Zimmer:3,
                Wohnfläche:[
                    { typ : 'Flur',qm:8.51,faktor:1},
                    { typ : 'Bad',qm:8.49,faktor:1},
                    { typ : 'Schlafen',qm:14.30,faktor:1},
                    { typ : 'Zimmer 1',qm:12.18,faktor:1},
                    { typ : 'Wohnen/Kochen',qm:33.89,faktor:1},
                    { typ : 'Abstell',qm:2.71,faktor:1},
                    { typ : 'Terrasse',qm:9.26,faktor:0.5},
                    { typ : 'Gesamt',qm:84.71},
                ],
                miete:{
                    kalt:1355.40,
                    nk:241.00,
                    warm:1596.40
                }},
                {id:'WE_12',
                Zimmer:1,
                Wohnfläche:[
                    { typ: 'Flur',qm:3.34,faktor:1},
                    { typ: 'Bad',qm:3.87,faktor:1},
                    { typ: 'Wohnen/Kochen/Schlafen',qm:16.66,faktor:1},
                    { typ: 'Terrasse',qm:7.27,faktor:0.5},
                    { typ: 'Gesamt',qm:27.51},
                ],
                miete:{
                    kalt:619.00,
                    nk:83.00,
                    warm:702.00
                }},
                {id:'WE_13',
                Zimmer:2,
                Wohnfläche:[
                    { typ: 'Flur',qm:5.65,faktor:1},
                    { typ: 'Bad',qm:6.68,faktor:1},
                    { typ: 'Schlafen',qm:12.60,faktor:1},
                    { typ: 'Wohnen/Kochen',qm:23.69,faktor:1},
                    { typ: 'Terrasse',qm:7.90,faktor:0.5},
                    { typ: 'Gesamt',qm:52.57},
                ],
                miete:{
                    kalt:867.40,
                    nk:156.00,
                    warm:1023.40
                }},
                {id:'WE_14B',
                Zimmer:3,
                Wohnfläche:[
                    { typ: 'Flur',qm:8.51,faktor:1},
                    { typ: 'Bad',qm:8.59,faktor:1},
                    { typ: 'Schlafen',qm:14.30,faktor:1},
                    { typ: 'Zimmer 1',qm:12.18,faktor:1},
                    { typ: 'Wohnen/Kochen',qm:34.25,faktor:1},
                    { typ: 'Abstell',qm:2.71,faktor:1},
                    { typ: 'Loggia',qm:5.08,faktor:0.5},
                    { typ: 'Gesamt',qm:83.08},
                ],
                miete:{
                    kalt:1345.90,
                    nk:240.00,
                    warm:1585.90
                }},
                {id:'WE_15',
                Zimmer:1,
                Wohnfläche:[
                    { typ: 'Flur',qm:3.34,faktor:1},
                    { typ: 'Bad',qm:3.87,faktor:1},
                    { typ: 'Wohnen/Kochen/Schlafen',qm:16.66,faktor:1},
                    { typ: 'Gesamt',qm:23.87},
                ],
                miete:{
                    kalt:561.00,
                    nk:73.00,
                    warm:634.00
                }},
                {id:'WE_16',
                Zimmer:2,
                Wohnfläche:[
                    { typ: 'Flur',qm:5.65,faktor:1},
                    { typ: 'Bad',qm:6.79,faktor:1},
                    { typ: 'Schlafen',qm:12.60,faktor:1},
                    { typ: 'Wohnen/Kochen',qm:23.87,faktor:1},
                    { typ: 'Loggia',qm:4.18,faktor:0.5},
                    { typ: 'Gesamt',qm:51.00},
                ],
                miete:{
                    kalt:872.10,
                    nk:150.00,
                    warm:1022.10
                }},
                {id:'WE_17B',
                Zimmer:3,
                Wohnfläche:[
                    { typ: 'Flur',qm:8.51,faktor:1},
                    { typ: 'Bad',qm:8.59,faktor:1},
                    { typ: 'Schlafen',qm:14.30,faktor:1},
                    { typ: 'Zimmer 1',qm:12.18,faktor:1},
                    { typ: 'Wohnen/Kochen',qm:34.25,faktor:1},
                    { typ: 'Abstell',qm:2.71,faktor:1},
                    { typ: 'Loggia',qm:5.08,faktor:0.5},
                    { typ: 'Gesamt',qm:83.08},
                ],
                miete:{
                    kalt:1362.50,
                    nk:240.00,
                    warm:1602.50
                }},
                {id:'WE_18',
                Zimmer:1,
                Wohnfläche:[
                    { typ: 'Flur',qm:3.34,faktor:1},
                    { typ: 'Bad',qm:3.87,faktor:1},
                    { typ: 'Wohnen/Kochen/Schlafen',qm:16.66,faktor:1},
                    { typ: 'Gesamt',qm:23.87},
                ],
                miete:{
                    kalt:580.00,
                    nk:73.00,
                    warm:653.00
                }},
                {id:'WE_19',
                Zimmer:2,
                Wohnfläche:[
                    { typ: 'Flur',qm:5.65,faktor:1},
                    { typ: 'Bad',qm:6.79,faktor:1},
                    { typ: 'Schlafen',qm:12.60,faktor:1},
                    { typ: 'Wohnen/Kochen',qm:23.87,faktor:1},
                    { typ: 'Loggia',qm:4.18,faktor:0.5},
                    { typ: 'Gesamt',qm:51.00},
                ],
                miete:{
                    kalt:902.70,
                    nk:151.00,
                    warm:1053.70
                }},
                {id:'WE_20',
                Zimmer:4,
                Wohnfläche:[
                    { typ: 'Flur',qm:12.28,faktor:1},
                    { typ: 'Bad',qm:7.15,faktor:1},
                    { typ: 'WC',qm:2.32,faktor:1},
                    { typ: 'Schlafen',qm:17.01,faktor:1},
                    { typ: 'Zimmer 1',qm:12.32,faktor:1},
                    { typ: 'Zimmer 2',qm:12.30,faktor:1},
                    { typ: 'Wohnen/Kochen',qm:33.00,faktor:1},
                    { typ: 'Abstell',qm:2.80,faktor:1},
                    { typ: 'Dachterrasse',qm:27.65,faktor:0.5},
                    { typ: 'Gesamt',qm:113.01},
                ],
                miete:{
                    kalt:1932.50,
                    nk:330.00,
                    warm:2262.50
                }},
                {id:'WE_21',
                Zimmer:4,
                Wohnfläche:[
                    { typ: 'Flur',qm:14.57,faktor:1},
                    { typ: 'Bad',qm:6.22,faktor:1},
                    { typ: 'WC',qm:2.87,faktor:1},
                    { typ: 'Schlafen',qm:16.72,faktor:1},
                    { typ: 'Zimmer 1',qm:12.49,faktor:1},
                    { typ: 'Zimmer 2',qm:8.35,faktor:1},
                    { typ: 'Wohnen/Kochen',qm:27.34,faktor:1},
                    { typ: 'Abstell',qm:2.79,faktor:1},
                    { typ: 'Dachterrasse',qm:21.42,faktor:0.5},
                    { typ: 'Gesamt',qm:102.06},
                ],
                miete:{
                    kalt:1786.05,
                    nk:300.00,
                    warm:2086.05
                }},
                {id:'WE_22',
                Zimmer:3,
                Wohnfläche:[
                    { typ: 'Flur',qm:7.89,faktor:1},
                    { typ: 'Bad',qm:8.63,faktor:1},
                    { typ: 'Schlafen',qm:14.78,faktor:1},
                    { typ: 'Zimmer 1',qm:10.94,faktor:1},
                    { typ: 'Wohnen/Kochen',qm:35.94,faktor:1},
                    { typ: 'Abstell',qm:2.71,faktor:1},
                    { typ: 'Terrasse',qm:9.62,faktor:0.5},
                    { typ: 'Gesamt',qm:86.06},
                ],
                miete:{
                    kalt:1428.60,
                    nk:252.00,
                    warm:1680.60
                }},
                {id:'WE_23',
                Zimmer:3,
                Wohnfläche:[
                    { typ: 'Flur',qm:8.55,faktor:1},
                    { typ: 'Bad',qm:7.23,faktor:1},
                    { typ: 'Schlafen',qm:15.26,faktor:1},
                    { typ: 'Zimmer 1',qm:12.61,faktor:1},
                    { typ: 'Wohnen/Kochen',qm:29.49,faktor:1},
                    { typ: 'Abstell',qm:1.78,faktor:1},
                    { typ: 'Terrasse',qm:21.12,faktor:0.5},
                    { typ: 'Gesamt',qm:85.48},
                ],
                miete:{
                    kalt:1402.00,
                    nk:250.00,
                    warm:1652.00
                }},
                {id:'WE_24B',
                Zimmer:3,
                Wohnfläche:[
                    { typ: 'Flur',qm:7.39,faktor:1},
                    { typ: 'Bad',qm:10.76,faktor:1},
                    { typ: 'Schlafen',qm:14.10,faktor:1},
                    { typ: 'Zimmer 1',qm:11.65,faktor:1},
                    { typ: 'Wohnen/Kochen',qm:30.51,faktor:1},
                    { typ: 'Terrasse',qm:9.22,faktor:0.5},
                    { typ: 'Gesamt',qm:79.02},
                ],
                miete:{
                    kalt:1264.30,
                    nk:235.00,
                    warm:1499.30
                }},
                {id:'WE_25',
                Zimmer:3,
                Wohnfläche:[
                    { typ: 'Flur',qm:7.89,faktor:1},
                    { typ: 'Bad',qm:8.63,faktor:1},
                    { typ: 'Schlafen',qm:14.33,faktor:1},
                    { typ: 'Zimmer 1',qm:11.76,faktor:1},
                    { typ: 'Wohnen/Kochen',qm:35.95,faktor:1},
                    { typ: 'Abstell',qm:3.07,faktor:1},
                    { typ: 'Loggia',qm:4.43,faktor:0.5},
                    { typ: 'Gesamt',qm:83.85},
                ],
                miete:{
                    kalt:1394.00,
                    nk:244.00,
                    warm:1638.00
                }},
                {id:'WE_26',
                Zimmer:3,
                Wohnfläche:[
                    { typ: 'Flur',qm:8.55,faktor:1},
                    { typ: 'Bad',qm:7.23,faktor:1},
                    { typ: 'Schlafen',qm:15.26,faktor:1},
                    { typ: 'Zimmer 1',qm:12.61,faktor:1},
                    { typ: 'Wohnen/Kochen',qm:29.49,faktor:1},
                    { typ: 'Abstell',qm:1.78,faktor:1},
                    { typ: 'Loggia',qm:9.48,faktor:0.5},
                    { typ: 'Gesamt',qm:79.66},
                ],
                miete:{
                    kalt:1380.00,
                    nk:232.00,
                    warm:1612.00
                }},
                {id:'WE_27B',
                Zimmer:3,
                Wohnfläche:[
                    { typ: 'Flur',qm:7.39,faktor:1},
                    { typ: 'Bad',qm:10.87,faktor:1},
                    { typ: 'Schlafen',qm:14.10,faktor:1},
                    { typ: 'Zimmer 1',qm:11.65,faktor:1},
                    { typ: 'Wohnen/Kochen',qm:30.87,faktor:1},
                    { typ: 'Loggia',qm:5.04,faktor:0.5},
                    { typ: 'Gesamt',qm:77.40},
                ],
                miete:{
                    kalt:1284.80,
                    nk:227.00,
                    warm:1511.80
                }},
                {id:'WE_28',
                Zimmer:3,
                Wohnfläche:[
                    { typ: 'Flur',qm:7.89,faktor:1},
                    { typ: 'Bad',qm:8.63,faktor:1},
                    { typ: 'Schlafen',qm:14.33,faktor:1},
                    { typ: 'Zimmer 1',qm:11.76,faktor:1},
                    { typ: 'Wohnen/Kochen',qm:35.94,faktor:1},
                    { typ: 'Abstell',qm:3.07,faktor:1},
                    { typ: 'Loggia',qm:4.43,faktor:0.5},
                    { typ: 'Gesamt',qm:83.84},
                ],
                miete:{
                    kalt:1458.80,
                    nk:244.00,
                    warm:1702.50
                }},
                {id:'WE_29',
                Zimmer:3,
                Wohnfläche:[
                    { typ: 'Flur',qm:8.55,faktor:1},
                    { typ: 'Bad',qm:7.23,faktor:1},
                    { typ: 'Schlafen',qm:15.26,faktor:1},
                    { typ: 'Zimmer 1',qm:12.61,faktor:1},
                    { typ: 'Wohnen/Kochen',qm:29.57,faktor:1},
                    { typ: 'Abstell',qm:1.78,faktor:1},
                    { typ: 'Loggia',qm:9.49,faktor:0.5},
                    { typ: 'Gesamt',qm:79.75},
                ],
                miete:{
                    kalt:1408.00,
                    nk:232.00,
                    warm:1640.00
                }},
                {id:'WE_30B',
                Zimmer:3,
                Wohnfläche:[
                    { typ: 'Flur',qm:7.39,faktor:1},
                    { typ: 'Bad',qm:10.87,faktor:1},
                    { typ: 'Schlafen',qm:14.10,faktor:1},
                    { typ: 'Zimmer 1',qm:11.65,faktor:1},
                    { typ: 'Wohnen/Kochen',qm:30.87,faktor:1},
                    { typ: 'Loggia',qm:5.04,faktor:0.5},
                    { typ: 'Gesamt',qm:77.40},
                ],
                miete:{
                    kalt:1401.00,
                    nk:227.00,
                    warm:1628.00
                }},
                {id:'WE_31',
                Zimmer:3,
                Wohnfläche:[
                    { typ: 'Flur',qm:8.03,faktor:1},
                    { typ: 'Bad',qm:7.89,faktor:1},
                    { typ: 'Schlafen',qm:11.41,faktor:1},
                    { typ: 'Zimmer 1',qm:8.51,faktor:1},
                    { typ: 'Wohnen/Kochen',qm:28.40,faktor:1},
                    { typ: 'Abstell',qm:3.52,faktor:1},
                    { typ: 'Loggia',qm:8.80,faktor:0.5},
                    { typ: 'Gesamt',qm:72.16},
                ],
                miete:{
                    kalt:1328.00,
                    nk:210.00,
                    warm:1538.00
                }},
                {id:'WE_32B',
                Zimmer:3,
                Wohnfläche:[
                    { typ: 'Flur',qm:9.55,faktor:1},
                    { typ: 'Bad',qm:6.79,faktor:1},
                    { typ: 'Schlafen',qm:16.17,faktor:1},
                    { typ: 'Zimmer 1',qm:11.45,faktor:1},
                    { typ: 'Wohnen/Kochen',qm:29.74,faktor:1},
                    { typ: 'Abstell',qm:1.73,faktor:1},
                    { typ: 'Loggia',qm:9.49,faktor:0.5},
                    { typ: 'Dachterrasse',qm:15.88,faktor:0.5},
                    { typ: 'Gesamt',qm:88.12},
                ],
                miete:{
                    kalt:1632.00,
                    nk:260.00,
                    warm:1892.00
                }},
            ]
            return options
        }
    },
    created(){
    },
    watch:{
        rooms(value){
            this.filterFlats(value,'rooms')
        },
        rentCold(value){
            this.filterFlats(value,'rent')
        },
        livingSpace(value){
            this.filterFlats(value,'space')
        }
    }

}
</script>

<style scoped>
.description{
    font-size: 1.2rem;
}
.descriptionInfo{
    font-size: 1rem;
}

.fixedHeight{
    min-height: 800px;
}
::v-deep .vueperslide__title {
  display: none !important;
}
</style>